import React, { createContext, useState } from "react";

export const Context = createContext();

const ContextProvider = (props) => {
  const [zipCodes, setZipCodes] = useState([]);
  const [basePrice, setBasePrice] = useState(0);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTechnician, setSelectedTechnician] = useState([{}]);
  const [invalidZipErrorMsg, setInvalidZipErrorMsg] = useState(false);
  const [googleResultsLoaded, setGoogleResultsLoaded] = useState(null);
  const [travelFee, setTravelFee] = useState(0);
  const [bookingDetails, setBookingDetails] = useState({});
  const [errorSendingForm, setErrorSendingForm] = useState(false);
  const [bookingSucceeded, setBookingSucceeded] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const value = {
    zipCodes,
    setZipCodes,
    basePrice,
    setBasePrice,
    selectedDay,
    setSelectedDay,
    selectedTechnician,
    setSelectedTechnician,
    invalidZipErrorMsg,
    setInvalidZipErrorMsg,
    googleResultsLoaded,
    setGoogleResultsLoaded,
    travelFee,
    setTravelFee,
    bookingDetails,
    setBookingDetails,
    errorSendingForm,
    setErrorSendingForm,
    bookingSucceeded,
    setBookingSucceeded,
    showLoading,
    setShowLoading,
  };
  const { Provider } = Context;
  return <Provider value={value}>{props.children}</Provider>;
};

export default ContextProvider;
