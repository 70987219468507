import { useFormikContext } from "formik";
import React, { useContext } from "react";
import { Context } from "../Context";
import LoadingSpinner from "../icons/LoadingSpinner";

const Review = ({ errorSubmittingBooking }) => {
  const formik = useFormikContext();
  const { travelFee, basePrice, showLoading } = useContext(Context);
  return (
    <div className="flex flex-col gap-4">
      {errorSubmittingBooking && (
        <>
          <span className="flex items-center text-sm text-red-600 bg-red-50 p-4 rounded-md gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
              />
            </svg>
            There was an error submitting your request. Please try again
            shortly.
          </span>
        </>
      )}
      {showLoading && <LoadingSpinner />}
      <span className="flex items-center text-sm text-blue-600 bg-blue-50 p-4 rounded-md gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
        Please ensure all details below are correct before you submit your
        appointment request.
      </span>
      <div className="flex gap-4">
        <div className="w-full flex flex-col">
          <div className="text-slate-500 font-medium text-xs mb-1">Service</div>
          <div className="leading-tight">{formik.values.service}</div>
        </div> <div className="w-full flex flex-col">
          <div className="text-slate-500 font-medium text-xs mb-1">Technician</div>
          <div className="leading-tight">{formik.values.technician}</div>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-full flex flex-col">
          <div className="text-slate-500 font-medium text-xs mb-1">
            Location
          </div>
          <div className="leading-tight">{formik.values.location}</div>
        </div>
        <div className="w-full flex flex-col">
          <div className="text-slate-500 font-medium text-xs mb-1">Price</div>
          <div className="leading-tight">${travelFee + basePrice}</div>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-full flex flex-col">
          <div className="text-slate-500 font-medium text-xs mb-1">Date</div>
          <div className="leading-tight">{formik.values.date}</div>
        </div>
        <div className="w-full flex flex-col">
          <div className="text-slate-500 font-medium text-xs mb-1">Time</div>
          <div className="leading-tight">{formik.values.time}</div>
        </div>
      </div>
      <hr />
      <div className="flex gap-4">
        <div className="w-full flex flex-col">
          <div className="text-slate-500 font-medium text-xs mb-1">
            First Name
          </div>
          <div className="leading-tight">{formik.values.firstName}</div>
        </div>
        <div className="w-full flex flex-col">
          <div className="text-slate-500 font-medium text-xs mb-1">
            Last Name
          </div>
          <div className="leading-tight">{formik.values.lastName}</div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="w-full flex flex-col">
          <div className="text-slate-500 font-medium text-xs mb-1">
            Phone Number
          </div>
          <div className="leading-tight">{formik.values.phone}</div>
        </div>
        <div className="w-full flex flex-col">
          <div className="text-slate-500 font-medium text-xs mb-1">Email</div>
          <div className="leading-tight">{formik.values.email}</div>
        </div>
      </div>
      {formik.values.location === "Mobile" && (
        <>
          <hr />
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="w-full flex flex-col">
              <div className="text-slate-500 font-medium text-xs mb-1">
                Address
              </div>
              <div className="leading-tight">
                {formik.values.streetAddress1}
              </div>
            </div>
            <div className="w-full flex flex-col">
              <div className="text-slate-500 font-medium text-xs mb-1">
                Address 2
              </div>
              <div className="leading-tight">
                {formik.values.streetAddress2 || "N/A"}
              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="w-full flex flex-col">
              <div className="text-slate-500 font-medium text-xs mb-1">
                City
              </div>
              <div className="leading-tight">{formik.values.city}</div>
            </div>
            <div className="w-full flex gap-4">
              <div className="sm:w-1/2">
                <div className="text-slate-500 font-medium text-xs mb-1">
                  State
                </div>
                <div className="leading-tight">{formik.values.state}</div>
              </div>
              <div className="sm:w-1/2">
                <div className="text-slate-500 font-medium text-xs mb-1">
                  ZIP Code
                </div>
                <div className="leading-tight">{formik.values.zipCode}</div>
              </div>
            </div>
          </div>
        </>
      )}
      {formik.values.notes && (
        <>
          <hr />
          <div className="flex gap-4">
            <div className="w-full flex flex-col">
              <div className="text-slate-500 font-medium text-xs mb-1">
                Notes
              </div>
              <div className="leading-tight">{formik.values.notes}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Review;
