import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { useContext, useState } from "react";
import { useField, useFormikContext } from "formik";
import ZipCode from "./ZipCode";
import { Context } from "../Context";

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label
        className="text-slate-800 font-medium text-sm mb-1"
        htmlFor={props.id || props.name}
      >
        {label + " "}
        {!props.optional && <sup className="text-red-500">*</sup>}
      </label>
      <input
        className="border-2 border-slate-100 rounded-md py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-brandOrange focus:border-2"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-xs text-red-500">{meta.error}</div>
      ) : null}
    </>
  );
};

const Address = () => {
  const formik = useFormikContext();
  let userZip = formik.values.zipCode;
  const { invalidZipErrorMsg, googleResultsLoaded, setGoogleResultsLoaded } =
    useContext(Context);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const apiAddress = results[0].formatted_address;

        let formattedAddress = apiAddress.split(", ");
        formattedAddress = {
          streetAddress1: formattedAddress[0],
          city: formattedAddress[1],
          state: formattedAddress[2].split(" ")[0],
          zipCode: formattedAddress[2].split(" ")[1],
        };

        if (status === "OK") {
          setGoogleResultsLoaded(true);
          setValue("");
        } else {
          setGoogleResultsLoaded(false);
        }

        formik.setValues({ ...formik.values, ...formattedAddress });
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion)}
          className="py-1 px-4 active:bg-slate-100 hover:bg-slate-100 cursor-pointer first:rounded-t-md last:rounded-b-md"
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div className="w-full flex flex-col" ref={ref}>
      <hr className="my-4" />
      {(!googleResultsLoaded || !formik.values.streetAddress1) && (
        <label className="text-slate-800 font-medium text-sm mb-1">
          Address <sup className="text-red-500">*</sup>
          <input
            className="w-full border-2 border-slate-100 rounded-md py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-brandOrange focus:border-2"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder="What's your address?"
            autoFocus
          />
        </label>
      )}
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && (
        <ul className="bg-white rounded-md border-slate-50">
          {renderSuggestions()}
        </ul>
      )}

      {formik.values.streetAddress1 && (
        <div className="w-full flex flex-col gap-4">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex flex-col w-full">
              <MyTextInput
                type="address"
                autoComplete="new-password"
                label="Address"
                id="streetAddress1"
                name="streetAddress1"
                placeholder="Enter your full address"
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex flex-col w-full">
              <MyTextInput
                type="address"
                autoComplete="new-password"
                label="Apartment / Floor / Other"
                id="streetAddress2"
                name="streetAddress2"
                placeholder="(optional)"
                optional="true"
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex flex-col w-full sm:w-1/2">
              <MyTextInput
                type="text"
                autoComplete="new-password"
                label="City"
                id="city"
                name="city"
                placeholder="City"
              />
            </div>
            <div className="flex box-border gap-4">
              <div className="flex flex-col w-1/2 sm:w-1/4">
                <MyTextInput
                  type="text"
                  autoComplete="new-password"
                  label="State"
                  id="state"
                  name="state"
                  placeholder="State"
                />
              </div>
              <div className="flex flex-col w-1/2 sm:w-1/4">
                <ZipCode userZip={userZip} />
              </div>
            </div>
          </div>
          {userZip.length === 5 && invalidZipErrorMsg && (
            <>
              <div className="mt-4 bg-red-50 text-red-500 text-xs p-4 rounded-md border border-red-500">
                For bookings in this area, please email us at{" "}
                <a href="mailto:julie@bronzedbyjulie.com" className="underline">
                  julie@bronzedbyjulie.com
                </a>
                .
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Address;
