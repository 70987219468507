import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Booking from "./views/Booking";
import "./App.css";
import ContextProvider from "./Context";
import Confirmation from "./views/Confirmation";

function App() {
  return (
    <ContextProvider>
      <div className="bg-slate-50 min-h-screen">
        <Header />
        <Routes>
          <Route path="/" element={<Booking />} />
          <Route path="/confirmation" element={<Confirmation />} />
        </Routes>
      </div>
    </ContextProvider>
  );
}

export default App;
