import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import { Context } from "../Context";
import Wizard from "../form/Wizard";
import Service from "../form/Service";
import Location from "../form/Location";
import Demographics from "../form/Demographics";
import DateAndTime from "../form/DateAndTime";
import { getServices } from "../services/getServices";
import { getZipCodes } from "../services/getZipCodes";
import { getHours } from "../services/getHours";
import ReferralSource from "../form/ReferralSource";
import Review from "../form/Review";
import LoadingSpinner from "../icons/LoadingSpinner";
import Confirmation from "./Confirmation";
import config from "../config";


const apiUrl = config.apiUrl;

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const WizardStep = ({ children }) => children;

const Booking = () => {
  const [services, setServices] = useState([]);
  const [hours, setHours] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [errorSubmittingBooking, setErrorSubmittingBooking] = useState(false);

  const {
    selectedDay,
    setSelectedDay,
    setZipCodes,
    bookingDetails,
    setBookingSucceeded,
    showLoading,
    setShowLoading,
  } = useContext(Context);

  const [searchParams] = useSearchParams();
  const urlService = searchParams.get("service");

  const loadData = async () => {
    const serviceData = await getServices();
    setServices(serviceData);

    const zipCodeData = await getZipCodes();
    setZipCodes(zipCodeData);

    const hoursData = await getHours();
    setHours(hoursData);

    if (urlService) {
      let service = serviceData.find((s) => s.id === urlService);
      if (!service) {
        setDataLoaded(true);
      } else {
        service = service.name;
        setSelectedService(service);
        setDataLoaded(true);
      }
    } else {
      setDataLoaded(true);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  const handleFormSubmit = () => {
    setShowLoading(true);
    fetch(`${apiUrl}/appt`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bookingDetails),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setShowLoading(false);
          setBookingSucceeded(true);
          navigate("/confirmation");
        } else {
          setShowLoading(false);
          setBookingSucceeded(false);
          setErrorSubmittingBooking(true);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div>
      {!dataLoaded ? (
        <div className="max-w-lg mx-auto mt-4 p-6 mb-12">
          <LoadingSpinner />
        </div>
      ) : (
        <Wizard
          initialValues={{
            service: selectedService || "",
            location: "",
            date: "",
            time: "",
            technician: "",
            zipCode: "",
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            notes: "",
            streetAddress1: "",
            streetAddress2: "",
            city: "",
            state: "",
            referralSource: "",
            otherReferralSource: "",
          }}
          // onSubmit={async (values) =>
          //   sleep(300).then(() => console.log("Wizard submit", values))
          // }
          onSubmit={handleFormSubmit}
        >
          <WizardStep
            onSubmit={(values) => {
              console.log("Step4 onSubmit", values.service);
            }}
            validationSchema={Yup.object({
              service: Yup.string().required("Please select a service."),
            })}
          >
            <Service
              services={services}
              setServices={setServices}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
            />
          </WizardStep>
          <WizardStep
            onSubmit={(values) =>
              console.log("Step4 onSubmit", values.location)
            }
            validationSchema={Yup.object({
              location: Yup.string().required("Please select a location."),
              zipCode: Yup.number().when("location", {
                is: "Mobile",
                then: Yup.number()
                  // .test(
                  //   "len",
                  //   "Enter a valid ZIP Code.",
                  //   (val) => val.toString().length === 5
                  // )
                  .required("This field is required"),
              }),
              streetAddress1: Yup.string().when("location", {
                is: "Mobile",
                then: Yup.string().required("This field is required"),
              }),
              streetAddress2: Yup.string().when("location", {
                is: "Mobile",
                then: Yup.string(),
              }),
              city: Yup.string().when("location", {
                is: "Mobile",
                then: Yup.string().required("This field is required"),
              }),
              state: Yup.string().when("location", {
                is: "Mobile",
                then: Yup.string().required("This field is required"),
              }),
            })}
          >
            <Location
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
            />
          </WizardStep>
          <WizardStep
            onSubmit={() => console.log("Date and time picked")}
            validationSchema={Yup.object({
              date: Yup.string().required("Please select a date."),
              time: Yup.string().required("Please select a time."),
              technician: Yup.string().required("Please select a technician."),
            })}
          >
            <DateAndTime
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
              hours={hours}
            />
          </WizardStep>
          <WizardStep
            onSubmit={() => console.log("Step1 onSubmit")}
            validationSchema={Yup.object({
              firstName: Yup.string().required("This field is required"),
              lastName: Yup.string().required("This field is required"),
              phone: Yup.string().required("This field is required"),
              email: Yup.string().required("This field is required"),
              notes: Yup.string(),
            })}
          >
            <Demographics />
          </WizardStep>
          <WizardStep
            onSubmit={() => console.log("Step1 onSubmit")}
            validationSchema={Yup.object({
              referralSource: Yup.string().required("This field is required"),
              otherReferralSource: Yup.string().when("referralSource", {
                is: "Other",
                then: Yup.string().required("This field is required"),
              }),
            })}
          >
            <ReferralSource />
          </WizardStep>
          <WizardStep>
            <Review errorSubmittingBooking={errorSubmittingBooking} />
          </WizardStep>
        </Wizard>
      )}
    </div>
  );
};

export default Booking;
