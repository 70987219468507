import React, { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { ErrorMessage, Field } from "formik";
import { Context } from "../Context";

const Service = ({
  services,
  setServices,
  selectedService,
  setSelectedService,
}) => {
  const [serviceFromUrl, setServiceFromUrl] = useState(null);
  const [searchParams] = useSearchParams();

  const { setBasePrice } = useContext(Context);

  const handleServiceSelection = (e) => {
    setSelectedService(e.target.value);
    const matchedService = services.find((x) => x.name === e.target.value);
    const servicePrice = matchedService.basePrice;
    setBasePrice(servicePrice);
  };

  useEffect(() => {
    setServiceFromUrl(searchParams.get("service"));
    if (searchParams.get("service")) {
      const matchedService = services.find(
        (x) => x.id === searchParams.get("service")
      );
      if (matchedService) {
        const servicePrice = matchedService.basePrice;
        setBasePrice(servicePrice);
      }
    } else {
      setServiceFromUrl(null);
    }
  }, []);

  return (
    <>
      <h2 className="font-bold text-xl cf-fbp">Choose Service</h2>
      <ErrorMessage
        className="text-xs text-red-500"
        component="div"
        name="service"
      />
      <div className="flex flex-col gap-4 mt-4 mb-8">
        {services.map((service) => {
          return (
            <label
              key={service.name}
              className={`${selectedService === service.name
                  ? "bg-brandOrange text-white border-brandOrange"
                  : "bg-white"
                } flex flex-col w-full border-2 border-slate-50 hover:border-3 hover:border-[#d57828] hover:scale-105 shadow-md shadow-indigo-100/50 space-y-0.5 p-4 mr-auto rounded-xl transition-all duration-300 ease-in-out cursor-pointer`}
            >
              <Field
                onClick={(e) => handleServiceSelection(e)}
                className="hidden appearance-none vendor-appearance-none"
                type="radio"
                name="service"
                value={service.name}
              />
              <div className="flex justify-between gap-4">
                <div className="font-bold">{service.name}</div>
                <div className="flex gap-2 text-right">
                  <div
                    className={`${selectedService === service.name
                        ? "text-white"
                        : "text-slate-500"
                      } text-[9px] leading-tight `}
                  >
                    <span>
                      starting
                      <br />
                      price
                    </span>
                  </div>
                  <div className="font-bold">
                    <span>$</span>
                    {service.basePrice}
                  </div>
                </div>
              </div>
            </label>
          );
        })}
      </div>
    </>
  );
};

export default Service;
