import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../Context";
import Checkmark from "../icons/Checkmark";

const currentYear = new Date().getFullYear();

const Confirmation = () => {
  const { bookingDetails, bookingSucceeded } = useContext(Context);

  const navigate = useNavigate();

  useEffect(() => {
    if (!bookingSucceeded) {
      navigate("/");
    }
  }, []);

  const finalPrice =
    bookingDetails.location === "Mobile"
      ? bookingDetails.basePrice + bookingDetails.travelFee
      : bookingDetails.basePrice;

  const article = bookingDetails.service === "Express Whitening" ? "an" : "a";

  let serviceColor;
  if (bookingDetails.service === "Express Whitening") {
    serviceColor = "blue";
  } else if (bookingDetails.service === "Brilliant Whitening") {
    serviceColor = "blue";
  } else {
    serviceColor = "tan";
  }

  return (
    <div className="max-w-xl mx-auto px-10 py-6 mb-16 bg-white mt-4 rounded-lg flex flex-col gap-8">
      <div className="flex justify-center">
        <Checkmark serviceColor={serviceColor} />
      </div>
      <div className="cf-fbp text-2xl text-center">
        {bookingDetails.firstName}—Thanks for requesting {article}{" "}
        {bookingDetails.service} appointment!
      </div>
      <div className="">
        Please note, this is not an appointment confirmation. We'll contact you
        shortly to confirm your appointment details.
      </div>
      <div
        className={`px-6 py-4 rounded ${
          serviceColor === "tan"
            ? "bg-brandLightTan border border-brandOrange"
            : "bg-brandLightSkyBlue border border-brandBlue"
        }`}
      >
        <div className="flex flex-col sm:flex-row">
          <div className="flex flex-col mb-2 sm:w-1/2">
            <div className="text-xs text-slate-600 ">Date</div>
            <div className="font-bold">{bookingDetails.date}</div>
          </div>
          <div className="flex flex-col mb-2 sm:w-1/2">
            <div className="text-xs text-slate-600">Time</div>
            <div className="font-bold">{bookingDetails.time}</div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="flex flex-col mb-2 sm:w-1/2">
            <span className="text-xs text-slate-600">Location</span>
            <span className="font-bold">
              {bookingDetails.location === "In-studio" ? (
                <>
                  <span>In-studio</span>
                  <br />
                  2080 Coney Island Ave.
                  <br />
                  Brooklyn, NY 11223
                </>
              ) : (
                <>
                  <span>Mobile</span>
                  <br />
                  {bookingDetails.streetAddress1}
                  <br />
                  {bookingDetails.streetAddress2 ? (
                    <>
                      {bookingDetails.streetAddress2}
                      <br />
                    </>
                  ) : null}
                  {bookingDetails.city}, {bookingDetails.state}{" "}
                  {bookingDetails.zipCode}
                </>
              )}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-xs text-slate-600">Price</span>
            <span className="font-bold">${finalPrice}</span>
            <span className="text-xs text-slate-600 mt-2">Technician</span>
            <span className="font-bold">{bookingDetails.technician}</span>
          </div>
        </div>
      </div>
      <div className="">
        In the meantime, please check out our 'What to Expect' page.
      </div>
      <a
        className="p-2 mb-4 bg-white text-center border-2 border-black rounded hover:bg-black hover:text-white transition-all duration-500 ease-in-out"
        href="https://bronzedbyjulie.com/what-to-expect/"
        target="_blank"
        rel="noreferrer"
      >
        Read What to Expect
      </a>
      <div className="flex mx-auto">
        <a
          className="text-xs underline text-slate-600 text-center font-bold"
          href="https://bronzedbyjulie.com"
        >
          Go Back Home
        </a>
      </div>

      <div className="text-[9px] tracking-widest text-center uppercase text-slate-600">
        Copyright © {currentYear} Bronzed by Julie. All rights reserved.
      </div>
    </div>
  );
};

export default Confirmation;
