const FormProgressBar = ({ stepNumber, totalSteps }) => {
  return (
    <div className="w-full bg-indigo-100/20 rounded-full h">
      <div
        className="bg-[#d57828] h-2 transition-width duration-700 ease"
        style={{ width: `${((stepNumber) / totalSteps) * 100}%` }}
      ></div>
    </div>
  );
};

export default FormProgressBar;
