import logo from "../assets/bronzed-by-julie-logo.png";

export default function Header() {
  return (
    <header className="bg-white shadow-md shadow-indigo-100/50 p-4 w-full flex flex-col items-center">
      {/* Replace with logo here */}
      <img src={logo} className="h-6" alt="logo" />
    </header>
  );
}
