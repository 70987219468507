import React, { useContext, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { validateZipCode } from "../services/validateZipCode";
import { Context } from "../Context";

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label
        className="text-slate-800 font-medium text-sm mb-1"
        htmlFor={props.id || props.name}
      >
        {label + " "}
        <sup className="text-red-500">*</sup>
      </label>
      <input
        className="border-2 border-slate-100 rounded-md py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-brandOrange focus:border-2"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-xs text-red-500">{meta.error}</div>
      ) : null}
    </>
  );
};

const ZipCode = ({ userZip }) => {
  const formik = useFormikContext();

  const { zipCodes, setInvalidZipErrorMsg } = useContext(Context);

  useEffect(() => {
    const showError = validateZipCode(userZip, zipCodes);
    if (formik.values.zipCode.length === 5 && showError) {
      setInvalidZipErrorMsg(false);
    } else {
      setInvalidZipErrorMsg(true);
    }
  }, [formik.values.zipCode]);

  return (
    <MyTextInput
      label="ZIP Code"
      id="zipCode"
      name="zipCode"
      placeholder="ZIP Code"
      maxLength="5"
      type="tel"
    />
  );
};

export default ZipCode;
