import React, { useState, useContext } from "react";
import { Form, Formik } from "formik";
import FormProgressBar from "../components/FormProgressBar";
import Price from "./Price";
import { Context } from "../Context";
import ArrowRight from "../icons/ArrowRight";
import ArrowLeft from "../icons/ArrowLeft";

const Wizard = ({ children, initialValues, onSubmit }) => {
  const [formSubmitting, setFormSubmitting] = useState(false)
  const { invalidZipErrorMsg, setBookingDetails, travelFee, basePrice } =
    useContext(Context);
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (values) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag);
      setBookingDetails({
        ...values,
        travelFee: travelFee,
        basePrice: basePrice,
      });
    }
    if (isLastStep) {
      setFormSubmitting(true)
      setBookingDetails({
        ...values,
        travelFee: travelFee,
        basePrice: basePrice,
      });
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
      enableReinitialize
    >
      {(formik) => (
        <>
          <FormProgressBar stepNumber={stepNumber} totalSteps={totalSteps} />
          <Form autoComplete="false" className="max-w-lg mx-auto p-6 mb-16">
            {step}
            <div className="w-screen fixed bottom-0 left-0 flex flex-col">
              {(stepNumber > 0 &&
                formik.values.location === "In-studio" &&
                !isLastStep && <Price />) ||
                (stepNumber > 0 &&
                  formik.values.zipCode.length === 5 &&
                  formik.values.location === "Mobile" &&
                  !isLastStep && <Price />)}
              <div className="flex">
                {(stepNumber > 0 && !formSubmitting) && (
                  <button
                    className="w-full p-3 text-center bg-brandTan flex justify-center gap-2"
                    onClick={() => previous(formik.values)}
                    type="button"
                  >
                    <ArrowLeft />
                    Back
                  </button>
                )}
                <button
                  className={`bg-brandOrange text-white w-full p-3 text-center flex justify-center gap-2`}
                  disabled={
                    formSubmitting || formik.isSubmitting ||
                    (stepNumber === 1 && invalidZipErrorMsg)
                  }
                  type="submit"
                >
                  {isLastStep ? (
                    "Submit"
                  ) : (
                    <>
                      Next
                      <ArrowRight />
                    </>
                  )}
                </button>
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default Wizard;
