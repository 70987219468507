import React, { useContext, useEffect, useState } from "react";
import { getDay, set } from "date-fns";
import { useField, useFormikContext, Field } from "formik";
import DateModal from "./DateModal";
import { Context } from "../Context";
import config from "../config";

const apiUrl = config.apiUrl;

const TimeSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      {/* <label
        className="block font-bold text-xl"
        htmlFor={props.id || props.name}
      >
        {label}
      </label> */}
      <select
        className="w-full appearance-none border-2 bg-white border-slate-100 rounded-md py-2 px-4"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="mt-2 text-xs text-red-500">{meta.error}</div>
      ) : null}
    </>
  );
};

const DateAndTime = ({ hours }) => {
  const { selectedDay, setSelectedTechnician } = useContext(Context);
  const [showDateModal, setShowDateModal] = useState(false);
  const [staff, setStaff] = useState([]);
  const [availableStaff, setAvailableStaff] = useState([]);
  const [noAvailableStaff, setNoAvailableStaff] = useState(false);

  const formik = useFormikContext();

  const launchDateModal = () => {
    setShowDateModal(true);
  }

  const handleSelectedStaff = (e) => {
    setSelectedTechnician(e.target.value);
    formik.setFieldValue("technician", e.target.value);
  }

  // console.log("hours: ", hours)
  // console.log("selectedDay: ", getDay(selectedDay))
  // console.log("filtered day", hours[0].timeBlocks.filter(day => day.day === getDay(selectedDay)))

  useEffect(() => {

    const getAvailableStaff = async () => {

      const res = await fetch(`${apiUrl}/schedules/available`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ date: new Date(formik.values.date) })
        }
      );
      const data = await res.json();

      console.log("date is:", formik.values.date)
      console.log('this ran')
      setAvailableStaff(data)

      if (data.length === 0) {
        setNoAvailableStaff(true)
      } else {
        setNoAvailableStaff(false)
      }

      if (formik.values.technician) {
        const selectedTech = data.some(s => s.firstName === formik.values.technician)

        if (!selectedTech) {
          formik.setFieldValue("technician", "");
        }
      }

    }

    if (formik.values.date) {

      getAvailableStaff()
    }

  }, [formik.values.date]);


  const isAvailable = (id) => {
    console.log("checking if available", id, availableStaff.some(s => s.id === id))

    return availableStaff.some(s => s.id === id);
  }

  useEffect(() => {
    const getStaff = async () => {
      const res = await fetch(`${apiUrl}/staff`);
      const data = await res.json();
      setStaff(data);
    }

    getStaff();

  }, []);

  return (
    <div className="w-full flex flex-col gap-4">
      <h2 className="font-bold text-xl cf-fbp">Choose Date &amp; Time</h2>
      <div className="flex flex-col sm:flex-row gap-4 ">
        <div className="w-full" onClick={launchDateModal}>
          <div className="appearance-none border-2 bg-white border-slate-100 rounded-md py-2 px-4">
            {selectedDay ? formik.values.date : "Choose date"}
          </div>
          <div className="mt-2 text-xs text-red-500">{formik.errors.date}</div>
        </div>
        <div className="w-full">
          <TimeSelect label="Choose Time" name="time" id="time">
            <option value="">Choose time range</option>
            {/* {getDay(selectedDay) === 6
              ? hours
                .filter((e) => e.day === "Saturday")
                .map((e) => {
                  return e.blocks.map((hourRange, index) => {
                    return (
                      <option
                        key={index}
                        value={`${hourRange.startTime} - ${hourRange.endTime}`}
                      >{`${hourRange.startTime} - ${hourRange.endTime}`}</option>
                    );
                  });
                })
              : hours
                .filter((e) => e.day === "Monday")
                .map((e) => {
                  return e.blocks.map((hourRange, index) => {
                    return (
                      <option
                        key={index}
                        value={`${hourRange.startTime} - ${hourRange.endTime}`}
                      >{`${hourRange.startTime} - ${hourRange.endTime}`}</option>
                    );
                  });
                })} */}

            {
              hours[0].timeBlocks.filter(day => day.day === getDay(selectedDay))
                .map((e) => {
                  return e.blocks.map((hourRange, index) => {
                    return (
                      <option
                        key={index}
                        value={`${hourRange.startTime} - ${hourRange.endTime}`}
                      >{`${hourRange.startTime} - ${hourRange.endTime}`}</option>
                    );
                  });
                })

            }
          </TimeSelect>
        </div>
      </div>
      {formik.values.date && formik.values.time && staff ? (
        <>
          <hr />
          <h2 className="font-bold text-xl cf-fbp">Choose Technician</h2>
          {noAvailableStaff ? <div className="bg-red-50 text-red-500 text-xs p-4 rounded-md border border-red-500">
            Sorry, there are no technicians available this day/time. Please select another day/time, or for assistance, contact us at <a href="tel:+16465863321"><span className="underline">(646) 586-3321</span>.</a>
          </div> : null}
          {formik.touched.technician && formik.errors.technician && !noAvailableStaff ? (
            <div className="text-xs text-red-500">{formik.errors.technician}</div>

          ) : null}
          {!noAvailableStaff && availableStaff.length > 1 ?
            <label
              className={`${formik.values.technician === 'Any'
                ? "bg-brandOrange text-white border-brandOrange"
                : "bg-white"
                } font-bold flex flex-col w-full border-2 border-slate-50 hover:border-3 hover:border-[#d57828] hover:scale-105 shadow-md shadow-indigo-100/50 space-y-0.5 p-4 mr-auto rounded-xl transition-all duration-300 ease-in-out cursor-pointer`}
            >
              <Field
                type="radio"
                name="technician"
                value="Any"
                onClick={(e) => handleSelectedStaff(e)}
                className="hidden appearance-none vendor-appearance-none"
              />
              Any
            </label> : null}
          {availableStaff && staff.map((staffMember) => {
            return (
              <label key={staffMember.id}
                className={`
                  ${formik.values.technician === staffMember.firstName
                    ? "bg-brandOrange text-white border-brandOrange"
                    : isAvailable(staffMember.id) ? "bg-white hover:scale-105 hover:border-3 hover:border-[#d57828]" : "bg-slate-100 text-slate-400"
                  } 
                font-bold flex justify-between w-full border-2 border-slate-50 shadow-md shadow-indigo-100/50 space-y-0.5 p-4 mr-auto rounded-xl transition-all duration-300 ease-in-out `

                }
              >
                <Field
                  disabled={!isAvailable(staffMember.id)}
                  type="radio"
                  name="technician"
                  value={staffMember.firstName}
                  onClick={(e) => handleSelectedStaff(e)}
                  className="hidden appearance-none vendor-appearance-none"
                />
                {staffMember.firstName} {isAvailable(staffMember.id) ? "" : <span className="text-xs font-normal italic">Unavailable this day/time</span>}
              </label>

            )
          })}
        </>
      ) : null}
      {/* {formik.values.date}
      {formik.values.time}
      {formik.values.technician} */}
      <DateModal
        showDateModal={showDateModal}
        setShowDateModal={setShowDateModal}
      />
    </div>
  );
};

export default DateAndTime;
