import React, { useContext } from "react";
import { useFormikContext } from "formik";
import { Context } from "../Context";

const Price = () => {
  const { zipCodes, basePrice, travelFee, setTravelFee } = useContext(Context);

  const formik = useFormikContext();
  let isMobile = formik.values.location === "Mobile";

  const enteredZip = zipCodes.find(
    (zip) => zip.name === parseInt(formik.values.zipCode)
  );

  if (enteredZip && isMobile) {
    setTravelFee(enteredZip.zone);
  } else {
    setTravelFee(0);
  }

  if (enteredZip && formik.values.service === "Custom Tan Package") {
    setTravelFee(enteredZip.zone * 5);
  }

  return (
    <div className="w-full py-3 bg-slate-50 text-center">
      <span className="text-sm border border-brandOrange bg-brandLightTan py-1 px-2 rounded-md font-semibold tracking-wider uppercase">
        Price: ${isMobile ? basePrice + travelFee : basePrice}
      </span>
    </div>
  );
};

export default Price;
