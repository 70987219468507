import React from "react";
import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import InputMask from "react-input-mask";

const PhoneInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label
        className="text-slate-800 font-medium text-sm mb-1"
        htmlFor={props.id || props.name}
      >
        {label + " "}
        <sup className="text-red-500">*</sup>
      </label>
      <InputMask
        className="border-2 border-slate-100 rounded-md py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-brandOrange focus:border-2"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-xs text-red-500">{meta.error}</div>
      ) : null}
    </>
  );
};

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label
        className="text-slate-800 font-medium text-sm mb-1"
        htmlFor={props.id || props.name}
      >
        {label + " "}
        <sup className="text-red-500">*</sup>
      </label>
      <input
        className="border-2 border-slate-100 rounded-md py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-brandOrange focus:border-2"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-xs text-red-500">{meta.error}</div>
      ) : null}
    </>
  );
};

const Demographics = () => {
  const formik = useFormikContext();
  return (
    <div className="flex flex-col gap-4">
      <h2 className="font-bold text-xl cf-fbp">Your Details</h2>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-col w-full">
          <MyTextInput
            type="text"
            autoComplete="new-password"
            label="First Name"
            id="firstName"
            name="firstName"
            placeholder="First Name"
          />
        </div>
        <div className="flex flex-col w-full">
          <MyTextInput
            type="text"
            autoComplete="new-password"
            label="Last Name"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-col w-full">
          <PhoneInput
            type="tel"
            autoComplete="new-password"
            label="Phone Number"
            name="phone"
            id="phone"
            placeholder="Phone Number"
            mask="1 (999) 999-9999"
          />
        </div>
        <div className="flex flex-col w-full">
          <MyTextInput
            type="email"
            autoComplete="new-password"
            label="Email"
            id="email"
            name="email"
            placeholder="Email"
          />
        </div>
      </div>
      <div className="w-full">
        <div className="flex flex-col w-full">
          <label
            htmlFor="notes"
            className="text-slate-800 font-medium text-sm mb-1"
          >
            Notes
          </label>
          <Field
            className="border-2 border-slate-100 rounded-md py-2 px-4 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-brandOrange"
            component="textarea"
            id="notes"
            name="notes"
            placeholder="Use this space to provide additional notes."
            type="text"
          />
          <ErrorMessage
            className="text-xs text-red-500"
            component="div"
            name="notes"
          />
        </div>
      </div>
    </div>
  );
};

export default Demographics;
