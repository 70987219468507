export const validateZipCode = (enteredZip, validZipCodes) => {
  if (enteredZip.length === 5) {
    if (
      validZipCodes.find(
        (zip) => zip.name === parseInt(enteredZip) && zip.zone !== 0
      )
    ) {
      return true;
    }
  } else {
    return false;
  }
};
