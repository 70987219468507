const environments = {
    development: {
      apiUrl: "https://bbj-appt-booking-dev.uk.r.appspot.com",
      // apiUrl: "http://localhost:3000",
    },
    production: {
      apiUrl: "https://bbj-appt-booking.ue.r.appspot.com",
      // apiUrl: "https://bbj-appt-booking-dev.uk.r.appspot.com",
    },
    local: {
      apiUrl: "http://localhost:3000",
    },
  };
  
  const environment = process.env.NODE_ENV || "local";
  
  export default environments[environment];
  