import React, { useContext, useEffect, useState } from "react";
import CustomDatePicker from "./CustomDatePicker";
import CloseBtn from "../icons/CloseBtn";
import { add, eachWeekendOfInterval, format, isSunday } from "date-fns";
import { Context } from "../Context";
import { useFormikContext } from "formik";
import config from "../config";

const apiUrl = config.apiUrl;

const today = new Date();
const oneYearAhead = add(today, { years: 10 });

// const sundays = eachWeekendOfInterval({
//   start: new Date(),
//   end: oneYearAhead,
// }).filter(isSunday);

// const disabledDays = [...sundays];
const disabledDays = [];

const DateModal = ({ showDateModal, setShowDateModal }) => {
  const [blockedDays, setBlockedDays] = useState(disabledDays);
  const { selectedDay, setSelectedDay } = useContext(Context);

  const formik = useFormikContext();

  const handleDaySelected = (day) => {
    setSelectedDay(day);
    formik.setFieldValue("date", format(day, "PP"));
    setTimeout(() => {
      setShowDateModal(false);
    }, 250);
  };

  const handleModalClose = () => {
    setShowDateModal(false);
  };

  useEffect(() => {
    fetch(`${apiUrl}/calendar`)
      .then((res) => res.json())
      .then((data) => {
        const tempBlockedDays = data[0].date.sort().map((day) => new Date(day));
        console.log("tempBlockedDays", tempBlockedDays)
        setBlockedDays([...blockedDays, ...tempBlockedDays]);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {showDateModal ? (
        <>
          <div className="justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-4">
            <div className="relative w-[24rem] my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-5xl bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-2xl font-semibold">Choose Date</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={handleModalClose}
                  >
                    <CloseBtn height={6} width={6} />
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex">
                  <CustomDatePicker
                    name="date"
                    id="date"
                    mode="single"
                    fromDate={today}
                    selected={selectedDay}
                    onSelect={handleDaySelected}
                    disabled={blockedDays}
                    className="mx-auto"
                  />
                </div>
                {/*footer*/}
                {/* <div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="w-full bg-brandOrange text-white active:bg-black active:scale-95 text-md px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    // onClick={handleAddHours}
                  >
                    Select Date
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default DateModal;
