import React from "react";
import { useField, useFormikContext } from "formik";

const referrals = [
  "Facebook",
  "Instagram",
  "Yelp",
  "Google",
  "Vogue Magazine",
  "Friend / Family",
  "Existing Client",
  "Other",
];

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label
        className="text-slate-800 font-medium mb-1 cf-fbp"
        htmlFor={props.id || props.name}
      >
        {label + " "}
        <sup className="text-red-500">*</sup>
      </label>
      <input
        className="border-2 border-slate-100 rounded-md py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-brandOrange focus:border-2"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-xs text-red-500">{meta.error}</div>
      ) : null}
    </>
  );
};

const ReferralSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      {/* <label
          className="block font-bold text-xl"
          htmlFor={props.id || props.name}
        >
          {label}
        </label> */}
      <select
        className="w-full appearance-none border-2 bg-white border-slate-100 rounded-md py-2 px-4"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-xs text-red-500">{meta.error}</div>
      ) : null}
    </>
  );
};

const ReferralSource = () => {
  const formik = useFormikContext();
  return (
    <div className="flex flex-col gap-4">
      <h2 className="font-bold text-xl cf-fbp">
        One last thing... How did you hear about us? {" "}
        <sup className="text-red-500">*</sup>
      </h2>
      <ReferralSelect name="referralSource" id="referralSource">
        <option value="">Choose an option</option>
        {referrals.map((r, index) => {
          return (
            <option key={index} value={r}>
              {r}
            </option>
          );
        })}
      </ReferralSelect>
      {formik.values.referralSource === "Other" && (
        <MyTextInput
          label="– Please specify"
          id="otherReferralSource"
          name="otherReferralSource"
          placeholder="Provide brief description"
        />
      )}
    </div>
  );
};

export default ReferralSource;
