import { ErrorMessage, Field, useFormikContext } from "formik";
import React from "react";
import Address from "./Address";

const locations = [
  { value: "In-studio", label: "In-studio", description: "You come to us" },
  { value: "Mobile", label: "Mobile", description: "We come to you" },
];

const Location = ({ selectedLocation, setSelectedLocation }) => {
  const formik = useFormikContext();
  return (
    <>
      <h2 className="font-bold text-xl cf-fbp">Choose Location</h2>
      <div className="flex gap-4 mt-4">
        {locations.map((location) => {
          return (
            <label
              key={location.value}
              className={`${
                selectedLocation === location.label
                  ? "bg-brandOrange text-white border-brandOrange"
                  : "bg-white"
              } flex flex-col w-full text-center border-2 border-slate-50 hover:border-3 hover:border-[#d57828] hover:scale-105 shadow-md shadow-indigo-100/50 space-y-0.5 mb-3 py-2 px-4 mr-auto rounded-xl transition-all duration-300 ease-in-out cursor-pointer`}
            >
              <Field
                onClick={(e) => setSelectedLocation(e.target.value)}
                className="hidden appearance-none vendor-appearance-none"
                type="radio"
                name="location"
                value={location.value}
              />
              <div className="font-bold">{location.label}</div>
              <div className="text-xs">{location.description}</div>
            </label>
          );
        })}
      </div>
      <ErrorMessage
        className="text-xs text-red-500"
        component="div"
        name="location"
      />
      {formik.values.location === "Mobile" && <Address />}
    </>
  );
};

export default Location;
