import React from "react";
import { useField } from "formik";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

const CustomDatePicker = ({ ...props }) => {
  const [field] = useField(props);

  return <DayPicker {...field} {...props} />;
};

export default CustomDatePicker;
